import decode from 'jwt-decode';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export enum RegistrationStep {
  PlasticCardTypeSelection = 1,
  PlasticCardEntry = 2,
  PlasticCardNotProvided = 3,
  EmailEntry = 4,
  EmailConfirmation = 5,
  PhoneEntry = 6,
  PhoneConfirmation = 7,
  ProfileInfo = 8,
  Complete = 9,
}

export interface TempTokenPayload {
  personalCode?: string;
  email?: string;
  emailConfirmed?: boolean;
  phone?: string;
  phoneConfirmed?: boolean;
  name?: string;
  surname?: string;
  cardNo?: string;
  accountId?: number;
}

export interface StrongAuthRegisterState {
  currentStep: RegistrationStep;
  tempToken?: string | null | undefined;
}

const initialState: StrongAuthRegisterState = {
  currentStep: RegistrationStep.PlasticCardTypeSelection,
};

export const decodeTempToken = (tempToken: string) => decode<TempTokenPayload>(tempToken);

export const strongAuthSlice = createSlice({
  name: 'strongAuth',
  initialState,
  reducers: {
    strongAuthCompleted: (state: StrongAuthRegisterState, action: PayloadAction<string>) => {
      state.tempToken = action.payload;
      const { accountId, email, emailConfirmed } = decodeTempToken(action.payload);
      if (accountId) {
        if (email && emailConfirmed) {
          state.currentStep = RegistrationStep.ProfileInfo;
        } else {
          state.currentStep = RegistrationStep.PlasticCardTypeSelection;
        }
      } else {
        state.currentStep = RegistrationStep.PlasticCardTypeSelection;
      }
    },
    plasticCardTypeChosen: (state: StrongAuthRegisterState, action: PayloadAction<boolean>) => {
      if (action.payload) state.currentStep = RegistrationStep.PlasticCardEntry;
      else state.currentStep = RegistrationStep.PlasticCardNotProvided;
    },
    plasticCardNoneBack: (state: StrongAuthRegisterState) => {
      state.currentStep = RegistrationStep.PlasticCardTypeSelection;
    },
    plasticCardCodeEntered: (state: StrongAuthRegisterState, action: PayloadAction<string>) => {
      state.tempToken = action.payload;
      const {} = decodeTempToken(action.payload);
      state.currentStep = RegistrationStep.EmailEntry;
    },
    emailEntered: (state: StrongAuthRegisterState, action: PayloadAction<string>) => {
      state.tempToken = action.payload;
      const {} = decodeTempToken(action.payload);
      state.currentStep = RegistrationStep.EmailConfirmation;
    },
    emailConfirmed: (state: StrongAuthRegisterState, action: PayloadAction<string>) => {
      state.tempToken = action.payload;
      const {} = decodeTempToken(action.payload);
      state.currentStep = RegistrationStep.PhoneEntry;
    },
    phoneEntered: (state: StrongAuthRegisterState, action: PayloadAction<string>) => {
      state.tempToken = action.payload;
      const {} = decodeTempToken(action.payload);
      state.currentStep = RegistrationStep.PhoneConfirmation;
    },
    phoneConfirmed: (state: StrongAuthRegisterState, action: PayloadAction<string>) => {
      state.tempToken = action.payload;
      const {} = decodeTempToken(action.payload);
      state.currentStep = RegistrationStep.ProfileInfo;
    },
    profileConfirmed: (state: StrongAuthRegisterState) => {
      state.currentStep = RegistrationStep.Complete;
    },
    complete: (state: StrongAuthRegisterState) => {
      state.tempToken = null;
    },
  },
});

export const {
  strongAuthCompleted,
  emailEntered,
  emailConfirmed,
  plasticCardTypeChosen,
  plasticCardCodeEntered,
  plasticCardNoneBack,
  phoneEntered,
  phoneConfirmed,
  profileConfirmed,
  complete,
} = strongAuthSlice.actions;
export default strongAuthSlice.reducer;
